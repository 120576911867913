import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { userSignup } from '../../../store/auth/authReducer'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import GuestLayout from '../../../components/Layout/GuestLayout/Index'

type FormValues = {
  username: string
  first_name: string
  last_name: string
  email: string
  password: string
  confirm_password: string
  role: string
  location: string
}

const Register = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const form = useForm<FormValues>()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = form
  const { user, token, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess || token) {
      if (!user?.isVerified) {
        navigate(`/auth/verify-email`)
      } else {
        if (user?.role === 'ROLE_ADMIN') {
          navigate('/admin/')
        } else {
          navigate(`/buyer/${user?.username}`)
        }
      }
      console.log('Logged in successfully')
      toast.success('Logged in successfully')
    }
  }, [user, token, isLoading, isError, isSuccess, message, navigate, dispatch])

  const onSubmit = (data: FormValues) => {
    if (data.password !== data.confirm_password) {
      toast.error('Passwords do not match')
      return
    }
    const formData = { ...data, role: 'ROLE_USER' }

    dispatch(userSignup(formData))
  }

  return (
    <GuestLayout>
      <div className="flex min-h-screen flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-auto h-16 mx-auto text-rose-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
            />
          </svg>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight dark:text-white text-gray-900">
            Start your 14-day free trial
          </h2>
          <p className="mt-2 text-center text-sm dark:text-white text-gray-600">
            Or{' '}
            <Link
              to="/auth/login"
              className="font-medium text-rose-600 hover:text-rose-500"
            >
              Sign in to your account
            </Link>
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white dark:bg-gray-950 dark:border dark:border-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6 mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Username
                </label>
                <div className="mt-1">
                  <input
                    id="username"
                    type="username"
                    {...register('username', {
                      required: 'Please enter your name',
                      pattern: {
                        value: /^[a-zA-Z0-9]*$/,
                        message: 'Please Enter a valid name ',
                      },
                    })}
                    className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                  />
                  {errors.username && (
                    <span role="alert" className="text-red-500 text-sm">
                      {errors.username?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="w-full block sm:flex justify-center items-center">
                <div className="mr-0 sm:mr-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    First Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="first_name"
                      type="first_name"
                      {...register('first_name', {
                        required: 'Please enter your name',
                      })}
                      className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                    />
                    {errors.first_name && (
                      <span role="alert" className="text-red-500 text-sm">
                        {errors.first_name?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="pt-6 sm:pt-0">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Last Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="last_name"
                      type="last_name"
                      {...register('last_name', {
                        required: 'Please enter your name',
                      })}
                      className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                    />
                    {errors.last_name && (
                      <span role="alert" className="text-red-500 text-sm">
                        {errors.last_name?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    {...register('email', {
                      required: 'Email address is required',
                      pattern: {
                        value:
                          /([a-z0-9][-a-z0-9_\+\.]*[a-z0-9])@([a-z0-9][-a-z0-9\.]*[a-z0-9]\.(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|([0-9]{1,3}\.{3}[0-9]{1,3}))/,
                        message: 'Please enter a valid email',
                      },
                      validate: {
                        notAdmin: (value) => {
                          return value !== 'admin@example.com' || 'Nice try!'
                        },
                      },
                    })}
                    className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                  />
                  {errors.email && (
                    <span role="alert" className="text-red-500 text-sm">
                      {errors.email?.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    {...register('password', {
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 9 characters',
                      },
                      required: 'Please enter your password',
                    })}
                    className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                  />
                  {errors.password && (
                    <span role="alert" className="text-red-500 text-sm">
                      {errors.password?.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Password Confirmation
                </label>
                <div className="mt-1">
                  <input
                    id="confirm_password"
                    type="password"
                    {...register('confirm_password', {
                      required: 'Please confirm your password',
                      validate: (value) => {
                        return (
                          value === getValues('password') ||
                          'Passwords do not match'
                        )
                      },
                    })}
                    className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                  />
                  {watch('confirm_password') !== watch('password') &&
                  getValues('confirm_password') ? (
                    <span role="alert" className="text-red-500 text-sm">
                      Password does not match
                    </span>
                  ) : null}
                </div>
              </div>

              <div>
                <label
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-gray-700 dark:text-white"
                >
                  Location
                </label>
                <select
                  id="location"
                  name="location"
                  {...register('location', {
                    required: 'Please select your location',
                    validate: (value) =>
                      value !== 'Select a location' ||
                      'Please select your location',
                  })}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:bg-gray-800 dark:text-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                >
                  <option>Select a location</option>
                  <option value="United States">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="Mexico">Mexico</option>
                </select>
                {errors.location && (
                  <span role="alert" className="text-red-500 text-sm">
                    {errors.location?.message}
                  </span>
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 dark:bg-gray-800 dark:text-white text-rose-600 focus:ring-rose-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-700 dark:text-white"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    to="/auth/forgot-password"
                    className="font-medium text-rose-600 hover:text-rose-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="flex w-full justify-center rounded-md border border-transparent bg-rose-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                >
                  {isLoading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-900 animate-spin dark:text-rose-600 fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <span className="ml-2">Register</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}

export default Register
