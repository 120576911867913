import { Fragment, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner/Index'
import { toast } from 'react-hot-toast'
import { manageSubscription } from '../../../store/subscription/subscriptionService'
import { deleteMe } from '../../../store/auth/authReducer'
import BuyerLayout from '../../../components/Layout/BuyerLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'
import { format } from 'date-fns'

const Profile = () => {
  const dispatch = useDispatch()
  const { user, token } = useSelector((state) => state.auth)
  const { isLoading, data } = useSelector((state) => state.subscriptions)

  if (isLoading) return <Spinner />

  console.log(data)

  const handleSubscriptionCancel = async (e) => {
    e.preventDefault()
    try {
      toast.loading('Loding your subscription details...', { duration: 500 })
      const { data } = await manageSubscription(token)
      console.log(data)
      window.open(data.url, '_self')
      toast.loading('Redirecting...', { duration: 2000 })
    } catch (error) {}

    // toast.success('Subscription cancelled successfully')
  }

  const handleAccountCancel = (
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
    token: any
  ) => {
    e.preventDefault()
    dispatch(deleteMe(token))
    toast.loading('Deleting your account...', { duration: 2000 })
    // toast.success('Account deleted successfully')
  }

  return (
    <BuyerLayout>
      <BreadCrumb
        title="Subscription Details"
        url={`/buyer/${user?.username}`}
      />
      <Panel
        title="Subscription Details"
        description="Your subscription details."
      >
        {data && (
          <div>
            <h2 className="text-base font-semibold leading-7 dark:text-white text-gray-900">
              Subscription Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              This information is only visible to you after you are subscribed
              to one of the paid plans
            </p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              {data &&
                data.map((subscription) => (
                  <Fragment key={subscription.id}>
                    <div className="pt-6 sm:flex">
                      <dt className="font-medium dark:text-white text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Subscription Price
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="dark:text-white text-gray-900">
                          {(subscription.plan.amount / 100).toLocaleString(
                            'en-US',
                            {
                              style: 'currency',
                              currency: 'USD',
                            }
                          )}{' '}
                          / {subscription.plan.interval}
                        </div>
                        <button
                          onClick={handleSubscriptionCancel}
                          className="font-semibold text-rose-600 hover:text-rose-500"
                        >
                          Update
                        </button>
                      </dd>
                    </div>
                    <div className="bg-white dark:bg-gray-950 shadow sm:rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6 dark:text-white text-gray-900">
                          Payment method
                        </h3>
                        <div className="mt-5">
                          <div className="rounded-md bg-gray-50 dark:bg-gray-950 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                            <h4 className="sr-only">Visa</h4>
                            <div className="sm:flex sm:items-start">
                              {subscription?.default_payment_method?.card.brand.toUpperCase() ===
                              'VISA' ? (
                                <svg
                                  className="h-8 w-auto sm:h-6 sm:flex-shrink-0"
                                  viewBox="0 0 36 24"
                                  aria-hidden="true"
                                >
                                  <rect
                                    width={36}
                                    height={24}
                                    fill="#224DBA"
                                    rx={4}
                                  />
                                  <path
                                    fill="#fff"
                                    d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                  />
                                </svg>
                              ) : null}{' '}
                              <div className="mt-3 sm:ml-4 sm:mt-0">
                                <div className="text-sm font-medium dark:text-white text-gray-900">
                                  Ending with{' '}
                                  {
                                    subscription?.default_payment_method?.card
                                      .last4
                                  }{' '}
                                </div>
                                <div className="mt-1 text-sm  dark:text-white text-gray-600 sm:flex sm:items-center">
                                  <div>
                                    Expires{' '}
                                    {subscription?.default_payment_method?.card
                                      .exp_month +
                                      '/' +
                                      subscription?.default_payment_method?.card
                                        .exp_year}
                                  </div>
                                  <span
                                    className="hidden sm:mx-2 sm:inline"
                                    aria-hidden="true"
                                  >
                                    &middot;
                                  </span>
                                  <div className="mt-1 sm:mt-0">
                                    Last updated on{' '}
                                    {format(
                                      new Date(subscription?.start_date * 1000),
                                      'M/y'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                              <button
                                type="button"
                                onClick={handleSubscriptionCancel}
                                className="inline-flex items-center rounded-md bg-white dark:bg-rose-600 px-3 py-3 text-sm font-semibold dark:text-white text-rose-900 shadow-sm ring-1 ring-inset ring-rose-300 hover:bg-rose-50"
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="pt-6 sm:flex">
                                                    <dt className="font-medium dark:text-white text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                                        Credit Card
                                                    </dt>
                                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className="dark:text-white text-gray-900">
                                                            {subscription?.default_payment_method?.card.brand.toUpperCase()}{' '}
                                                            {subscription?.default_payment_method?.card.last4}{' '}
                                                        </div>
                                                        <button
                                                            onClick={handleSubscriptionCancel}
                                                            className="font-semibold text-rose-600 hover:text-rose-500"
                                                        >
                                                            Update
                                                        </button>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium dark:text-white text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                                        Current Period Ends
                                                    </dt>
                                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className="dark:text-white text-gray-900">
                                                            {moment(subscription.current_period_end * 1000)
                                                                .format('dddd MMMM Do YYYY')
                                                                .toString()}
                                                        </div>
                                                        <button
                                                            onClick={handleSubscriptionCancel}
                                                            className="font-semibold text-rose-600 hover:text-rose-500"
                                                        >
                                                            Update
                                                        </button>
                                                    </dd>
                                                </div> */}
                    {subscription.cancellation_details.reason ? (
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium dark:text-white text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                          Cancellation Reason
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="dark:text-white text-gray-900">
                            {subscription.cancellation_details.reason}
                          </div>
                        </dd>
                      </div>
                    ) : null}
                    {subscription.cancellation_details.comment ? (
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium dark:text-white text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                          Cancellation Comment
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="dark:text-white text-gray-900">
                            {subscription.cancellation_details.comment}
                          </div>
                        </dd>
                      </div>
                    ) : null}
                    {subscription.cancellation_details.feedback ? (
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium dark:text-white text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                          Cancellation Feedback
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="dark:text-white text-gray-900">
                            {subscription.cancellation_details.feedback}
                          </div>
                        </dd>
                      </div>
                    ) : null}
                    <div className="pt-6 sm:flex">
                      <dt className="font-medium dark:text-white text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Delete my account
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="dark:text-white text-gray-900"></div>
                        <button
                          onClick={(e, token) => handleAccountCancel(e, token)}
                          className="font-semibold cursor-pointer rounded-lg bg-red-600 text-white py-2 px-4 hover:bg-red-500"
                        >
                          Yes, delete my account
                        </button>
                      </dd>
                    </div>
                  </Fragment>
                ))}
            </dl>
          </div>
        )}
      </Panel>
    </BuyerLayout>
  )
}
export default Profile
