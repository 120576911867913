import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import GuestLayout from '../../../components/Layout/GuestLayout/Index'
import {
  logout,
  resendVerifyEmail,
  reset,
  verifyEmail,
} from '../../../store/auth/authReducer'
import toast from 'react-hot-toast'

const OTP_LENGTH = 6

const Verification = () => {
  const { user, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  )
  const [otp, setOtp] = useState(new Array(OTP_LENGTH).fill(''))
  const [activeOtpIndex, setActiveOtpIndex] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const inputRef = useRef()

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value

    // Ensure the pasted value is not longer than 1 character
    const newValue = value.length <= 1 ? value : value.substring(0, 1)

    const newOtp = [...otp]
    newOtp[index] = newValue

    if (!newValue) {
      focusPreviousInputField(index)
    } else {
      focusNextInputField(index)

      // Check if OTP is complete
      if (newOtp.every((digit) => digit !== '' && digit !== undefined)) {
        // All digits are filled, submit the OTP
        const otpString = newOtp.join('')
        dispatch(verifyEmail({ OTP: otpString }))
      }
    }

    setOtp([...newOtp])
  }

  const focusNextInputField = (index: number) => {
    if (index < OTP_LENGTH - 1) {
      setActiveOtpIndex(index + 1)
    }
  }

  const focusPreviousInputField = (index: number) => {
    if (index >= 0) {
      setActiveOtpIndex(index - 1)
    }
  }

  const handleKeyDown = (
    { key }: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (key === 'Backspace') {
      // Check if the current input is empty
      if (!otp[index]) {
        focusPreviousInputField(index)
      } else {
        // Perform the Backspace action
        const newOtp = [...otp]
        newOtp[index] = '' // Clear the current input
        setOtp([...newOtp])
      }
    }
  }

  const handleResendOTP = (e: any) => {
    e.preventDefault()
    toast.success('OTP sent successfully')
    dispatch(resendVerifyEmail())
  }

  const handleLogout = () => {
    dispatch(logout())
    toast.success('Logged out successfully')
    dispatch(reset())
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const otpString = otp.join('')
    dispatch(verifyEmail({ OTP: otpString }))
  }

  useEffect(() => {
    inputRef.current?.focus()

    if (!user) {
      navigate('/auth/login')
    }

    if (user?.isVerified) {
      navigate(`/buyer/${user?.username}`)
    }
  }, [user, activeOtpIndex, isSuccess, isError, message])

  return (
    <GuestLayout>
      <div className="flex min-h-screen flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-auto h-16 mx-auto text-rose-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
            />
          </svg>

          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Please verify your account
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    OTP
                  </label>
                </div>
                <div className="mt-1">
                  <div className="py-4 flex justify-center items-center">
                    {otp.map((_, index) => {
                      return (
                        <input
                          ref={activeOtpIndex === index ? inputRef : null}
                          key={index}
                          name="otp"
                          type="number"
                          value={otp[index] || ''}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onChange={(e) => handleOtpChange(e, index)}
                          className="w-14 h-14 text-center text-2xl text-black font-semibold rounded focus:border-white bg-transparent outline-none spin-button-none"
                        />
                      )
                    })}
                  </div>
                  {message ? (
                    <span role="alert" className="text-red-500 text-sm">
                      {message}
                    </span>
                  ) : null}
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center"></div>

                  <div className="text-sm">
                    <Link
                      onClick={(e) => handleResendOTP(e)}
                      to="#"
                      className="font-medium text-rose-600 hover:text-rose-500"
                    >
                      Resend OTP
                    </Link>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-rose-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
              >
                Verify
              </button>
              <button
                onClick={handleLogout}
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Sign Out
              </button>
            </form>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}
export default Verification
